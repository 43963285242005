import { tradeStatsList } from '@/api/trade_stat'
import { tradeProductStatList, tradeStatsSettleList, tradeStatsSettleProductList } from '../../../api/trade_stat'


export const module_config = {
  TRADE_MONT_STATS: {
    name: '月度汇总（支付）',
    api: tradeStatsList,
    chars: [],
    number_columns: ['GMV'],
    fields: {
      '序号': 'index',
      '红人昵称': 'artist_nickname',
      '合作时长': '_duration',
      '月份': '_month',
      'GMV': 'gmv'
    }
  },
  TRADE_MONT_STATS_DY: {
    name: '月度汇总（支付）',
    api: tradeStatsList,
    chars: [],
    number_columns: ['退前总GMW', '退后总GMW', '结算总GMV', '退前总佣金', '退后总佣金', '结算总佣金', '达人补贴'],
    fields: {
      '序号': 'index',
      '红人昵称': 'artist_nickname',
      '合作时长': '_duration',
      '月份': '_month',
      '退前总GMW': 'gmv_return_bef',
      '退后总GMW': 'gmv_return_aft',
      '结算总GMV': 'gmv_settle',
      '退前总佣金': 'ci_return_bef',
      '退后总佣金': 'ci_return_aft',
      '结算总佣金': 'ci',
      '达人补贴': 'expert_subsidy',
      '订单退货率': '_rate_return',
      '订单佣金率': '_rate_ci',
      '订单结算比': '_rate_done'
    }
  },
  TRADE_M_S_ST_DY: {
    name: '月度汇总',
    api: tradeStatsSettleList,
    chars: [],
    number_columns: ['结算金额', '结算佣金收入（达人）', '结算佣金收入（机构）', '结算佣金收入（含税合计）', '结算佣金收入（不含税合计）', '达人补贴', '结算后退款'],
    fields: {
      '序号': 'index',
      '红人昵称': 'artist_nickname',
      '合作时长': '_duration',
      '月份': '_month',
      '结算金额': 'settle_amount',
      '结算佣金收入（达人）': 'sci_kol',
      '结算佣金收入（机构）': 'sci_agency',
      '结算佣金收入（含税合计）': 'sci',
      '结算佣金收入（不含税合计）': 'sci_no',
      '达人补贴': 'expert_subsidy',
      '结算后退款': 'custom_return'
    }
  },
  TRADE_M_S_ST_P_DY: {
    name: '直播保量数据',
    api: tradeStatsSettleProductList,
    chars: [],
    number_columns: ['订单数', '结算金额', '总佣金收入'],
    fields: {
      '月份': '_month',
      '平台': 'platform_name',
      '达人账号': 'artist_account',
      '达人昵称[以星广汇昵称为准]': 'ad_nickname',
      '商品名称': 'product_title',
      '流量来源': 'flow_source',
      '店铺名称': 'shop_name',
      '订单数': 'trade_count',
      '结算金额': 'settle_amount',
      '总佣金收入': 'sci',
      '佣金比例（%）': '_commission_rate'
    }
  },
  TRADE_PRODUCT_STAT: {
    name: '商品统计',
    api: tradeProductStatList,
    chars: [],
    number_columns: [],
    fields: {}
  }
}
