import * as request from '@/utils/request'

/**
 * 列表
 * @param data
 * @returns {Promise}
 */
export function tradeStatsList(data) {
  return request.post('/trade_stats/list', data)
}

/**
 * 查询验证
 * @param data
 * @returns {Promise}
 */
export function tradeStatsListValidate(data) {
  return request.post('/trade_stats/list_validate', data)
}

/**
 * 配置读取
 * @param data
 * @returns {Promise}
 */
export function tradeStatsGetConfig(data) {
  return request.post('/trade_stats/get_config', data)
}

/**
 * 设置配置
 * @param data
 * @returns {Promise}
 */
export function tradeStatsSetConfig(data) {
  return request.post('/trade_stats/set_config', data)
}

/**
 * 初始化月度汇总数据
 * @param data
 * @returns {Promise}
 */
export function tradeStatsInit(data) {
  return request.post('/trade_stats/init_stat', data)
}

/**
 * 统计列表状态
 * @param data
 * @returns {Promise}
 */
export function tradeStatsListStatus(data) {
  return request.post('/trade_stats/list_status', data)
}


/**
 *
 * @param data
 * @returns {*}
 */
export function tradeStatsSettleList(data) {
  return request.post('/trades_stats_settle/list', data)
}

/**
 * @param data
 * @returns {*}
 */
export function tradeStatsSettleProductList(data) {
  return request.post('/trades_stats_settle/product_list', data)
}


export function tradeStatsSettleSetCR(data) {
  return request.post('/trades_stats_settle/set_custom_return', data)
}

/**
 * 产品统计
 * @param data
 * @returns {*}
 */
export function tradeProductStatList(data) {
  return request.post('/trades_products_stat/list', data)
}

export function tradeProductStatInit(data) {
  return request.post('/trades_products_stat/init_stat', data)
}
