<template>
  <div class="export-by-page-container ml-button handle-button">
    <el-button v-if="isText" type="text" :size="size" :icon="icon" @click="handelClick">{{ txt }}
    </el-button>
    <el-button v-else type="primary" :size="size" :round="round" :icon="icon" plain @click="handelClick">{{ txt }}
    </el-button>
    <div v-if="loading" class="mask">
      <div style="text-align: center;">
        <el-progress type="circle" :percentage="percentage" text-color="white" />
        <h2 style="color: #FFFFFF;text-align: center;">下载中...</h2>
        <h3 style="color: #FFFFFF;text-align: center;">
          下载数 {{ total|numberFormat }} 条，已用时 {{ (interval_ms / 1000)|numberFormat(2) }} s
        </h3>
      </div>
    </div>
  </div>
</template>
<script>
import { export_json_to_excel } from '@/utils/export/excel'
import { module_config } from './index.js'
import api_common from '@/api/common'

const getExportPage = api_common.getPageCommon
export default {
  name: 'ExportByPage',
  props: {
    search: {
      type: Object,
      required: true
    },
    module: {
      type: String,
      required: true
    },
    columns: {
      type: [Object, Boolean],
      default() {
        return false
      }
    },
    numberColumns: {
      type: [Array, Boolean],
      default() {
        return false
      }
    },
    filename: {
      type: String,
      default() {
        return ''
      }
    },
    size: {
      type: String,
      default() {
        return ''
      }
    },
    round: {
      type: Boolean,
      default() {
        return true
      }
    },
    icon: {
      type: String,
      default() {
        return 'el-icon-download'
      }
    },
    txt: {
      type: String,
      default() {
        return '导出'
      }
    },
    // eslint-disable-next-line vue/require-default-prop
    pageSize: Number,
    isText: Boolean
  },
  data() {
    return {
      loading: false,
      interval: null,
      interval_ms: 0,
      total: 0,
      percentage: 0
    }
  },
  methods: {
    /**
     * 数值列配置
     * @param columns
     */
    getSourceDataStyle: function(columns) {
      const obj = {}
      columns.map(column => {
        obj[column] = { data_type: 'number' }
      })
      return obj
    },
    /**
     * 开始导出
     * @returns {Promise<void>}
     */
    handelClick: async function() {
      let { fields, number_columns } = module_config[this.module]

      let fieldMap = this.columns ? this.columns : fields
      number_columns = this.numberColumns ? this.numberColumns : number_columns
      if (fieldMap === undefined) {
        this.$message.error('导出异常！')
        return
      }

      const _this = this
      _this.interval = setInterval(function() {
        _this.interval_ms += 10
      }, 10)

      this.percentage = 0
      this.loading = true
      const json = await this.getData()
      this.loading = false
      const { array: tableData, merges } = this.filterJson(json)

      export_json_to_excel(
        {
          fieldMap,
          sourceData: tableData,
          filename: _this.filename ? `${_this.filename}` : (module_config[this.module].name || '导出数据'),
          mergeColumns: merges,
          sourceDataStyle: _this.getSourceDataStyle(number_columns)
        }, _this.callbackDone)
    },
    /**
     * 导出完成后回调
     */
    callbackDone: function() {
      const _this = this
      _this.$message.success('导出成功')
      _this.interval_ms = 0
      _this.interval && clearInterval(_this.interval)
    },
    getData: async function() {
      const { api } = module_config[this.module]
      if (api === undefined) {
        this.$message.error('导出异常！')
        return
      }

      let array = []
      const params = { ...{}, ...this.search }
      params.current_page = 1
      params.page_size = 1
      const { pages, list } = await getExportPage(api, params)
      let total = 0
      if (pages === undefined || pages.total === undefined) {
        // 无分页
        total = list.length || 0
      } else {
        // 有分页
        total = pages.total || 0
      }
      if (this.pageSize) {
        params.page_size = this.pageSize
      } else {
        if (total > 10000) {
          params.page_size = 1000
        } else if (total > 1000) {
          params.page_size = 500
        } else {
          params.page_size = 100
        }
      }
      this.total = total
      for (let i = 0; i < total; i += params.page_size) {
        const { list: data } = await getExportPage(api, params)
        array = [...array, ...data]
        params.current_page++
        this.percentage = parseInt((i * 100 / total) + '')
      }
      return array
    },
    filterJson(json) {
      const array = []
      const merges = []
      json.forEach((row, index) => {
        const obj = { ...{}, ...row }
        obj.index = index + 1
        array.push(obj)
      })
      return { array, merges }
    }
  }
}

</script>
<style lang="scss">
.export-by-page-container {
  display: inline;

  //.el-button [class*=el-icon-] + span {
  //  margin-left: 0;
  //}

  .mask {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, .8); /*一般遮罩随便设置一个颜色*/
    z-index: 1001;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
